
window.dataLayer = window.dataLayer || [];

if(window.gtag === undefined) {
    function gtag() {
        dataLayer.push(arguments);
    }
}
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970;path=/';
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
function checkCookieConsentIsSet() {
    let cookieConsentInfo = getCookie('cgcookieconsent');
    if(cookieConsentInfo)
        return cookieConsentInfo.length > 0;
    return false;
}
function setConsentCookie(preferences = 1, analytics = 1, marketing = 1) {
    let consent = {mandatory: 1, preferences: preferences, analytics: analytics, marketing: marketing};
    let consentCookieText = 'mandatory='+consent.mandatory+'-preferences='+consent.preferences+'-analytics='+consent.analytics+'-marketing='+consent.marketing;
    setCookie('cgcookieconsent', consentCookieText);
    document.body.setAttribute("data-cookie", "1");
    hideCookieConsentPopUp();
    sendConsent();
}
function showCookieConsentPopUp() {
    document.getElementById('cookie-consent-wrapper').style.display = 'block';
    document.documentElement.style.overflowY = 'hidden';
    if(document.getElementById('overlay')) {
        document.getElementById('overlay').style.display = 'block';
    }
}
function hideCookieConsentPopUp() {
    document.getElementById('cookie-consent-wrapper').style.display = 'none';
    document.documentElement.style.overflowY = 'auto';
    if(document.getElementById('overlay')) {
        document.getElementById('overlay').style.display = 'none';
    }
}
function sendConsent() {
    if(checkCookieConsentIsSet()) {
        let consentedCookies = getCookie('cgcookieconsent');
        let consentedCookiesArray = consentedCookies.split('-');
        let preferences = consentedCookiesArray[1].includes(1)?'granted':'denied';
        let analytics = consentedCookiesArray[2].includes(1)?'granted':'denied';
        let marketing = consentedCookiesArray[3].includes(1)?'granted':'denied';
        gtag('consent', 'update', {
            ad_storage: marketing,
            ad_user_data: marketing,
            ad_personalization: marketing,
            analytics_storage: analytics,
            personalization_storage: preferences
        });
    } else {
        gtag('consent', 'default', {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied'
        });
    }
}
if(!checkCookieConsentIsSet()) {
    sendConsent();
    showCookieConsentPopUp();
} else {
    sendConsent();
    document.body.setAttribute("data-cookie", "1");
    hideCookieConsentPopUp();
}
document.getElementById('cookie-manageSettingsBtn').addEventListener('click', ()=> {
    document.getElementById('cookie-consent-wrapper').setAttribute('data-mode', 'advanced');
});
document.getElementById('cookie-back-button').addEventListener('click', ()=> {
    document.getElementById('cookie-consent-wrapper').setAttribute('data-mode', 'basic');
});
document.getElementById('cookie-acceptSelectedBtn').addEventListener('click', ()=> {
    let preferences = document.getElementById('cookie-preferences-toggle').checked?1:0;
    let analytics = document.getElementById('cookie-analytics-toggle').checked?1:0;
    let marketing = document.getElementById('cookie-marketing-toggle').checked?1:0;
    setConsentCookie(preferences,analytics,marketing);
});
document.getElementById('cookie-rejectNonEssentialBtn').addEventListener('click', ()=> {
    setConsentCookie(0,0,0);
});
document.getElementById('cookie-acceptAllBtn').addEventListener('click', ()=> {
    setConsentCookie();
});
